import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {promptConstants} from '../../reducers/prompts.reducer';

function ConfirmPrompt() {
    const dispatch = useDispatch();
    const { title, message, callback } = useSelector((store: any) => store.promptReducer);

    const handleClose = () => dispatch({ type: promptConstants.HIDE_CONFIRM_PROMPT });

    const handleConfirm = (): void => {
        callback();
        handleClose();
    };

    return (
        <Dialog open={Boolean(callback)} fullWidth maxWidth="sm" onClose={handleClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{message}</DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Abbrechen</Button>
                <Button variant="contained" onClick={handleConfirm}>Bestätigen</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmPrompt;
