import React, {useContext} from 'react';
import {IAddress} from '../../models/address';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {clearFormErrors} from '../../actions/formError.actions';
import {createAddress} from '../../service/address.service';
import {RestClientContext} from "../../context";

interface AddAddressDialogProps {
    onFinished: (address: IAddress) => void,
    children: JSX.Element,
}

const initialData: IAddress = {
    location: '',
    addressStreet: '',
    addressZip: '',
    addressLocation: '',
};

function AddAddressDialog({ onFinished, children }: AddAddressDialogProps) {
    const restClient = useContext(RestClientContext)!;
    const dispatch = useDispatch();
    const formErrors = useSelector((store: any) => store.formErrorReducer.errors);
    const [open, setOpen] = React.useState<boolean>(false);
    const [processing, setProcessing] = React.useState<boolean>(false);
    const [address, setAddress] = React.useState<IAddress>(initialData);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const handleSubmit = async () => {
        dispatch(clearFormErrors());
        setProcessing(true);

        try {
            const updatedAddress = await createAddress(restClient, address);

            setOpen(false);
            onFinished(updatedAddress);
            setAddress(initialData);
        } catch (ex) {}

        setProcessing(false);
    };

    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
                <DialogTitle>Adresse hinzufügen</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} style={{ marginTop: 5 }}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                size="small"
                                label="Location"
                                value={address.location}
                                onChange={event => setAddress({ ...address, location: event.target.value })}
                                error={Boolean(formErrors.location)}
                                helperText={formErrors.location}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                size="small"
                                label="Straße und Hausnummer"
                                value={address.addressStreet}
                                onChange={event => setAddress({ ...address, addressStreet: event.target.value })}
                                error={Boolean(formErrors.addressStreet)}
                                helperText={formErrors.addressStreet}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                required
                                fullWidth
                                size="small"
                                label="PLZ"
                                value={address.addressZip}
                                onChange={event => setAddress({ ...address, addressZip: event.target.value })}
                                error={Boolean(formErrors.addressZip)}
                                helperText={formErrors.addressZip}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                required
                                fullWidth
                                size="small"
                                label="Ort"
                                value={address.addressLocation}
                                onChange={event => setAddress({ ...address, addressLocation: event.target.value })}
                                error={Boolean(formErrors.addressLocation)}
                                helperText={formErrors.addressLocation}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Abbrechen</Button>
                    <Button disabled={processing} variant="contained" onClick={handleSubmit}>Speichern</Button>
                </DialogActions>
            </Dialog>

            <span onClick={handleOpen}>
                {children}
            </span>
        </React.Fragment>
    );
}

export default AddAddressDialog;
