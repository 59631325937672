import React from 'react';
import {Alert, Box, IconButton} from '@mui/material';
import {useHistory} from 'react-router-dom';
import ReplayIcon from '@mui/icons-material/Replay';

interface ErrorHandlerProps {
    error?: string,
    children: any,
}

function ErrorHandler({ error, children }: ErrorHandlerProps) {
    const history = useHistory();

    if (error) {
        return (
            <Box sx={{ width: '100%' }}>
                <Alert
                    severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => history.go(0)}
                        >
                            <ReplayIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                    {error}
                </Alert>
            </Box>
        );
    }

    return children;
}

export default ErrorHandler;
