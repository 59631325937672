import { formErrorReducer } from './formError.reducer';
import { promptReducer } from './prompts.reducer';
import { uiReducer } from './ui.reducer';
import { cartReducer } from './cart.reducer';

export const rootReducer = {
    formErrorReducer,
    promptReducer,
    uiReducer,
    cartReducer,
};
