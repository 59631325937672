import { IArticleFetched } from '../models/article';
import { AxiosInstance } from "axios";

export const getArticles = async (rest: AxiosInstance, query?: string, tenantId?: string, programId?: string, categoryId?: string): Promise<IArticleFetched[]> => {

    const params = new URLSearchParams();

    if (query) {
        params.append('query', query);
    }
    if (tenantId && tenantId !== '-1') {
        params.append('tenant', tenantId);
    }
    if (programId) {
        params.append('program', programId);
    }
    if (categoryId) {
        params.append('category', categoryId);
    }

    const { data } = await rest.get(`article/articles?${params.toString()}`);

    return data;
};

export const getArticle = async (rest: AxiosInstance, articleId: string): Promise<IArticleFetched> => {
    const { data } = await rest.get(`article/articles/${articleId}`);

    return data;
};
