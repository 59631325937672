import React, {useContext} from 'react';
import {Alert, Breadcrumbs, Button, Container, Grid, Grow, Link, Paper, Typography} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import {IUser} from '../models/user';
import {useDispatch} from 'react-redux';
import {updateUser} from '../service/user.service';
import {clearFormErrors} from '../actions/formError.actions';
import UserForm from '../components/forms/UserForm';
import {useHistory} from 'react-router-dom';
import {RestClientContext} from "../context";

interface ProfileProps {
    user: IUser,
}

function Profile({ user }: ProfileProps) {
    const restClient = useContext(RestClientContext)!;
    const dispatch = useDispatch();
    const history = useHistory();
    const [processing, setProcessing] = React.useState<boolean>(false);
    const [saved, setSaved] = React.useState<boolean>(false);
    const [newUser, setNewUser] = React.useState<IUser>(user);

    const handleSubmit = async () => {
        dispatch(clearFormErrors());
        setProcessing(true);

        try {
            await updateUser(restClient, newUser);
        } catch (ex) {
            setProcessing(false);
            return;
        }

        setProcessing(false);
        setSaved(true);
    };

    return (
        <Container maxWidth="md">
            <Paper style={{ padding: 20 }} variant="elevation" elevation={3}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link
                                        underline="hover"
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        color="inherit"
                                        href="/"
                                    >
                                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                        Dashboard
                                    </Link>
                                    <Typography color="text.primary">Profil</Typography>
                                </Breadcrumbs>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grow in={saved} unmountOnExit>
                        <Grid item xs={12}>
                            <Alert severity="success" onClose={() => setSaved(false)}>
                                Die Änderungen wurden erfolgreich gespeichert.
                            </Alert>
                        </Grid>
                    </Grow>
                    <Grid item xs={12}>
                        <UserForm user={newUser} onChange={setNewUser} showAll />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Button variant="contained" onClick={() => history.push('/')}>Zurück</Button>
                            </Grid>
                            <Grid item>
                                <Button onClick={handleSubmit} variant="contained" disabled={processing}>Speichern</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default Profile;
