import { rootReducer } from './reducers/root.reducer';
import { applyMiddleware, combineReducers, createStore } from 'redux';

const initialState = {};

const asyncFunctionMiddleware = (storeAPI: any) => (next: any) => (action: any) => {
    // If the "action" is actually a function instead...
    if (typeof action === 'function') {
        // then call the function and pass `dispatch` and `getState` as arguments
        return action(storeAPI.dispatch, storeAPI.getState);
    }

    // Otherwise, it's a normal action - send it onwards
    return next(action);
};

const store = createStore(
    combineReducers(rootReducer),
    initialState,
    applyMiddleware(asyncFunctionMiddleware),
);

export default store;
