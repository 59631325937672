import store from '../store';
import { clearFormErrors, setFormErrors } from '../actions/formError.actions';
import axios, { AxiosError } from 'axios';

export const onError = (err: AxiosError<any>) => {

    const { response } = err;

    if (!response) {
        // store.dispatch(showModal(<InfoDialog title="Fehler" severity="error" message={err.message} />));
        return;
    }

    store.dispatch(clearFormErrors());

    switch (response.status) {
        // check for client error
        // ignore some http errors which are handled directly in the component
        case 400:
            break;
        case 422:
            if (response.data && response.data.errors) {
                store.dispatch(setFormErrors(response.data.errors));
            }
            break;

        case 403:
            // store.dispatch(showModal(<InfoDialog title="Anfrage abgelehnt" severity="warning" message={response.data.error || 'Anfrage nicht autorisiert.'} />));
            break;

        case 404:
            // store.dispatch(showModal(<InfoDialog title="Nicht gefunden" severity="info" message={response.data.error} />));
            break;

        case 405:
            // store.dispatch(showModal(<InfoDialog title="Ungültige Anfrage" severity="error" message={response.data.message} />));
            break;

        case 423:
        case 425:
            // store.dispatch(showModal(<InfoDialog title="Zugang verweigert" severity="warning" message={response.data.error} />));
            break;

        // check for rate limit error
        case 429:
            // const retryAfter = response.headers['retry-after'] ?
            //     Math.round(((response.headers['retry-after'] / 60) + Number.EPSILON) * 100) / 100 :
            //     'ein Paar';
            // store.dispatch(showModal(<InfoDialog title="Zu viele Anfragen" severity="warning" message={`Bitte probiere es in ${retryAfter} Minute(n) erneut.`} />));
            break;

        case 401:
        case 440:
            // setTimeout(() =>
            //         store.dispatch(showModal(
            //             <InfoDialog
            //                 onNext={() => store.dispatch(logout(false))}
            //                 title="Abgemeldet"
            //                 severity="info"
            //                 message="Deine Sitzung ist abgelaufen. Bitte melde dich erneut an."
            //             />
            //         )),
            //     500);
            break;

        // check for internal server error
        case 500:
            // report to sentry
            // Sentry.withScope(scope => {
            //     scope.setTag('http-error', 'internal');
            //     // @ts-ignore
            //     scope.setContext('response', response);
            //     scope.setContext('request', response.request);
            //
            //     Sentry.captureException(err);
            // });
            //
            // store.dispatch(showModal(<InfoDialog title="Serverfehler" severity="error" message={err.response ? err.response.data?.message : err.message} />));
            break;
        case 503:
            // maintenance mode
            // store.dispatch(showModal(<InfoDialog title="Wartungsarbeiten" severity="warning" message="" />));
            break;
        default:
            // store.dispatch(showModal(<InfoDialog title="Fehler" severity="error" message={response.data.error || err.message} />));
            break;
    }

    return Promise.reject(err);
};
