import { IAddress } from '../models/address';
import { AxiosInstance } from "axios";

export const getAddresses = async (rest: AxiosInstance): Promise<IAddress[]> => {
    const { data } = await rest.get('addresses');

    return data;
};

export const createAddress = async (rest: AxiosInstance, address: IAddress): Promise<IAddress> => {
    const { data } = await rest.post('addresses', address);

    return data;
};
