import React from 'react';
import Keycloak from 'keycloak-js';

function Logout({ keyCloak }: { keyCloak: Keycloak.KeycloakInstance }) {
    React.useEffect(() => {
        keyCloak.logout();
    }, []);

    return null;
}

export default Logout;
