import React from 'react';

interface ImagePreviewProps {
    url: string,
}

function ImagePreview({ url }: ImagePreviewProps) {
    return (
        <img
            loading="lazy"
            src={url}
            style={{ width: '100%', maxHeight: 150 }}
            alt="Uploaded File"
        />
    );
}

export default ImagePreview;
