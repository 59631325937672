import { IArticleAmount, StoredArticle } from '../models/article';
import { cartConstants } from '../reducers/cart.reducer';
import { getArticle } from '../service/article.service';
import { AxiosInstance } from "axios";

export const loadCart = (rest: AxiosInstance, dispatch: any) => {
    const saved = localStorage.getItem('cart');

    if (!saved) {
        return;
    }

    const storedArticles: StoredArticle[] = JSON.parse(saved);

    storedArticles.forEach(storedArticle => {
        getArticle(rest, storedArticle.id)
            .then(article => {
                const articleAmount: IArticleAmount = { ...article, amount: storedArticle.amount };

                dispatch({
                    type: cartConstants.ADD_TO_CART_PLAIN,
                    payload: articleAmount,
                });
            });
    });
};

export const addToCart = (article: IArticleAmount) => ({
    type: cartConstants.ADD_TO_CART,
    payload: article,
});

export const removeFromCart = (article: IArticleAmount) => ({
    type: cartConstants.REMOVE_FROM_CART,
    payload: article,
});

export const clearCart = () => ({
    type: cartConstants.CLEAR_CART,
});
