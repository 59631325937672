export const uiConstants = {
    TOGGLE_DARK_MODE: 'TOGGLE_DARK_MODE',
};

const initialState = {
    darkMode: localStorage.getItem('dark_mode') !== null,
};

export const uiReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case uiConstants.TOGGLE_DARK_MODE:
            const newValue = !state.darkMode;

            if (newValue) {
                localStorage.setItem('dark_mode', 'true');
            } else {
                localStorage.removeItem('dark_mode');
            }

            return {
                darkMode: newValue,
            };
        default:
            return state;
    }
};
