import React, {useContext} from 'react';
import {Autocomplete, AutocompleteValue, Grid, IconButton, TextField} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {ErrorAble} from '../../models/components';
import {IProject} from '../../models/project';
import {getProjects} from '../../service/project.service';
import ProjectDialog from '../dialogs/ProjectDialog';
import {IUser} from '../../models/user';
import {RestClientContext} from "../../context";

interface SelectProjectProps extends ErrorAble {
    onChange: (project?: IProject) => void,
    user: IUser,
    value?: string,
    readOnly?: boolean,
    showAll?: boolean,
}

function SelectProject({ onChange, value, readOnly, error, user, showAll }: SelectProjectProps) {
    const restClient = useContext(RestClientContext)!;
    const [selected, setSelected] = React.useState<IProject>();
    const [projects, setProjects] = React.useState<IProject[]>([]);

    React.useEffect(() => fetchData(), []);

    React.useEffect(() => onChange(selected), [selected]);

    const fetchData = () => {
        getProjects(restClient, undefined, showAll ? 'all' : 'current')
            .then(setProjects);
    };

    function getSelected() {
        if (projects.length > 0 && selected) {
            return selected;
        }

        return null;
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs>
                <Autocomplete
                    size="small"
                    fullWidth
                    disablePortal
                    loading={projects.length < 1}
                    options={projects}
                    disabled={readOnly}
                    getOptionLabel={project => project.name}
                    value={getSelected()}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required
                            label="Projekt auswählen"
                            error={Boolean(error)}
                            helperText={error}
                        />
                    )}
                    onChange={(event, value: AutocompleteValue<any, any, any, any>) => {
                        if (!value) {
                            setSelected(undefined);
                            return;
                        }

                        setSelected(value);
                    }}
                />
            </Grid>
            <Grid item>
                <Grid container justifyContent="center">
                    <Grid item>
                        <ProjectDialog onFinish={fetchData} initialUser={user} userName={user.name}>
                            <IconButton>
                                <AddCircleIcon />
                            </IconButton>
                        </ProjectDialog>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SelectProject;
