import React from 'react';
import {IUser} from '../../models/user';
import {Grid, IconButton, InputAdornment, TextField, Tooltip, Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import {Edit} from '@mui/icons-material';

interface UserFormProps {
    user: IUser,
    onChange: (user: IUser) => void,
    showAll?: boolean,
}

function UserForm({ user, onChange, showAll }: UserFormProps) {
    const formErrors = useSelector((store: any) => store.formErrorReducer.errors);

    return (
        <Grid container spacing={2}>
            {showAll && (
                <React.Fragment>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            size="small"
                            label="Vorname, Nachname"
                            value={user.name}
                            onChange={event => onChange({ ...user, name: event.target.value })}
                            error={Boolean(formErrors.name)}
                            helperText={formErrors.name}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            size="small"
                            label="Email"
                            value={user.email}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            disabled
                            size="small"
                            type="password"
                            label="Passwort"
                            value="aaaaaaaaaaaaaaaaa"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <a href="https://id-us.urban-scope.de/auth/realms/LOKA/account/#/security/signingin" target="_blank" rel="noreferrer">
                                            <Tooltip title="Passwort ändern">
                                                <IconButton>
                                                    <Edit />
                                                </IconButton>
                                            </Tooltip>
                                        </a>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} />
                </React.Fragment>
            )}

            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    size="small"
                    label="Bereich / Abteilung"
                    value={user.region}
                    onChange={event => onChange({ ...user, region: event.target.value })}
                    error={Boolean(formErrors.region)}
                    helperText={formErrors.region}
                />
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    size="small"
                    label="Straße & Hausnummer"
                    value={user.addressStreet}
                    onChange={event => onChange({ ...user, addressStreet: event.target.value })}
                    error={Boolean(formErrors.addressStreet)}
                    helperText={formErrors.addressStreet}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    fullWidth
                    size="small"
                    label="Haus"
                    value={user.addressHouseNumber}
                    onChange={event => onChange({ ...user, addressHouseNumber: event.target.value })}
                    error={Boolean(formErrors.addressHouseNumber)}
                    helperText={formErrors.addressHouseNumber}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    fullWidth
                    size="small"
                    label="Raumnummer"
                    value={user.addressRoomNumber}
                    onChange={event => onChange({ ...user, addressRoomNumber: event.target.value })}
                    error={Boolean(formErrors.addressRoomNumber)}
                    helperText={formErrors.addressRoomNumber}
                />
            </Grid>
            <Grid item xs={0} md={4} />
            <Grid item xs={12} md={4}>
                <TextField
                    required
                    fullWidth
                    size="small"
                    label="PLZ"
                    value={user.addressZip}
                    onChange={event => onChange({ ...user, addressZip: event.target.value })}
                    error={Boolean(formErrors.addressZip)}
                    helperText={formErrors.addressZip}
                />
            </Grid>
            <Grid item xs={12} md={8}>
                <TextField
                    required
                    fullWidth
                    size="small"
                    label="Ort"
                    value={user.addressLocation}
                    onChange={event => onChange({ ...user, addressLocation: event.target.value })}
                    error={Boolean(formErrors.addressLocation)}
                    helperText={formErrors.addressLocation}
                />
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12} md={6}>
                <TextField
                    required
                    fullWidth
                    size="small"
                    label="Telefonnummer"
                    value={user.phoneNumber}
                    onChange={event => onChange({ ...user, phoneNumber: event.target.value })}
                    error={Boolean(formErrors.phoneNumber)}
                    helperText={formErrors.phoneNumber}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    size="small"
                    label="Handynummer"
                    value={user.mobileNumber}
                    onChange={event => onChange({ ...user, mobileNumber: event.target.value })}
                    error={Boolean(formErrors.mobileNumber)}
                    helperText={formErrors.mobileNumber}
                />
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12}>
                <Typography variant="h6">Vertretung</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    size="small"
                    label="Vorname"
                    value={user.replacementGivenName}
                    onChange={event => onChange({ ...user, replacementGivenName: event.target.value })}
                    error={Boolean(formErrors.replacementGivenName)}
                    helperText={formErrors.replacementGivenName}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    size="small"
                    label="Name"
                    value={user.replacementSurname}
                    onChange={event => onChange({ ...user, replacementSurname: event.target.value })}
                    error={Boolean(formErrors.replacementSurname)}
                    helperText={formErrors.replacementSurname}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    size="small"
                    label="E-Mail Adresse"
                    value={user.replacementEmail}
                    onChange={event => onChange({ ...user, replacementEmail: event.target.value })}
                    error={Boolean(formErrors.replacementEmail)}
                    helperText={formErrors.replacementEmail}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    size="small"
                    label="Telefonnummer"
                    value={user.replacementPhoneNumber}
                    onChange={event => onChange({ ...user, replacementPhoneNumber: event.target.value })}
                    error={Boolean(formErrors.replacementPhoneNumber)}
                    helperText={formErrors.replacementPhoneNumber}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    size="small"
                    label="Bereich / Abteilung"
                    value={user.replacementRegion}
                    onChange={event => onChange({ ...user, replacementRegion: event.target.value })}
                    error={Boolean(formErrors.replacementRegion)}
                    helperText={formErrors.replacementRegion}
                />
            </Grid>
        </Grid>
    )
}

export default UserForm;
