import { IUser } from '../models/user';
import { AxiosInstance } from "axios";

export const updateUser = (rest: AxiosInstance, user: IUser): Promise<void> => {
    return rest.post('user', user);
};

export const getUser = async (rest: AxiosInstance): Promise<IUser> => {
    const { data } = await rest.get('user');

    return data;
};