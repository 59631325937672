import React, {useContext} from 'react';
import {Autocomplete, AutocompleteValue, Button, ButtonGroup, Grid, Skeleton, TextField} from '@mui/material';
import {ErrorAble} from '../../models/components';
import {ITenantFetched} from '../../models/tenant';
import {getTenants} from '../../service/tenant.service';
import {RestClientContext} from "../../context";

interface SelectTenantProps extends ErrorAble {
    onChange: (tenant?: ITenantFetched) => void,
    onAll?: () => void,
    onFirst?: (tenant: ITenantFetched) => void,
    value?: string,
    readOnly?: boolean,
    required?: boolean,
    buttonGroup?: boolean,
    allowAll?: boolean,
}

function SelectTenant({ onChange, onAll, onFirst, value, readOnly, error, required, buttonGroup, allowAll }: SelectTenantProps) {
    const restClient = useContext(RestClientContext)!;
    const [tenants, setTenants] = React.useState<ITenantFetched[]>([]);

    React.useEffect(() => fetchData(), []);

    const fetchData = () => {
        getTenants(restClient)
            .then(tenants => {
                setTenants(tenants);

                if (onFirst && tenants.length > 0) {
                    onFirst(tenants[0]);
                }
            });
    };

    if (buttonGroup) {
        if (tenants.length < 1) {
            return <Skeleton variant="rectangular" width={200} height={30} />;
        }

        return (
            <ButtonGroup variant="outlined">
                {tenants.map(tenant => (
                    <Button
                        key={tenant.name}
                        variant={value === tenant.id ? 'contained' : 'outlined'}
                        onClick={() => onChange(tenant)}
                    >
                        {tenant.name}
                    </Button>
                ))}
                {allowAll && (
                    <Button
                        variant={value === undefined ? 'contained' : 'outlined'}
                        onClick={() => {
                            if (onAll) {
                                onAll();
                            }
                        }}
                    >
                        Alle
                    </Button>
                )}
            </ButtonGroup>
        );
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs>
                {tenants.length > 0 && (
                    <Autocomplete
                        size="small"
                        fullWidth
                        disablePortal
                        loading={tenants.length < 1}
                        options={tenants}
                        disabled={readOnly}
                        getOptionLabel={tenant => tenant.name}
                        value={tenants.find(tenant => tenant.id === value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                required={required}
                                label="Kunde"
                                error={Boolean(error)}
                                helperText={error}
                            />
                        )}
                        onChange={(event, value: AutocompleteValue<any, any, any, any>) => {
                            if (!value) {
                                onChange(undefined);
                                return;
                            }

                            onChange(value);
                        }}
                    />
                )}
            </Grid>
        </Grid>
    );
}

export default SelectTenant;
