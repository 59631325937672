import { IProject } from '../models/project';
import dayjs from 'dayjs';
import { AxiosInstance } from "axios";

export const duplicateProject = (rest: AxiosInstance, id: string): Promise<any> => {
    return rest.post(`projects/${id}/duplicate`);
};

export const getProjects = async (rest: AxiosInstance, query?: string, type: string = 'current', year?: number, status?: string, tenant?: string): Promise<IProject[]> => {
    const { data } = await rest.get(`projects?query=${query ?? ''}&type=${type}&year=${year ?? ''}&status=${status ?? ''}&tenant=${tenant ?? ''}`);

    return data.map((project: IProject) => ({
        ...project,

        // initialize dayjs objects
        startDate: dayjs(project.startDate),
        endDate: dayjs(project.endDate),
        constructionBegin: project.constructionBegin ? dayjs(project.constructionBegin) : null,
        deconstructionBegin: project.constructionBegin ? dayjs(project.deconstructionBegin) : null,
        deliveryDate: project.deliveryDate ? dayjs(project.deliveryDate) : null,
        pickupDate: project.pickupDate ? dayjs(project.pickupDate) : null,
        requestedAt: project.requestedAt ? dayjs(project.requestedAt): null,
        createdAt: dayjs(project.createdAt),
        updatedAt: dayjs(project.updatedAt),
    }));
};

export const getProject = async (rest: AxiosInstance, projectId: string): Promise<IProject> => {
    const { data } = await rest.get(`projects/${projectId}`);

    return {
        ...data,

        // initialize dayjs objects
        startDate: dayjs(data.startDate),
        endDate: dayjs(data.endDate),
        deliveryDate: dayjs(data.deliveryDate),
        pickupDate: data.pickupDate ? dayjs(data.pickupDate) : null,
        requestedAt: data.requestedAt ? dayjs(data.requestedAt): null,
        createdAt: dayjs(data.createdAt),
        updatedAt: dayjs(data.updatedAt),
    }
};

export const createProject = (rest: AxiosInstance, project: IProject): Promise<void> => {
    return rest.post('projects', project);
};

export const updateProject = (rest: AxiosInstance, project: IProject): Promise<void> => {
    return rest.post(`projects/${project.id}`, project);
};

export const cancelProject = (rest: AxiosInstance, projectId: string): Promise<void> => {
    return rest.post(`projects/${projectId}/cancel`);
}

export const requestProject = (rest: AxiosInstance, projectId: string): Promise<void> => {
    return rest.post(`projects/${projectId}/request`);
};
