import { Dayjs } from 'dayjs';
import { IUser } from './user';
import { IAddress } from './address';
import { IContactPerson } from './contact';

export interface IProject {
    id?: string,
    projectId?: number,
    name: string,
    pb?: string,
    kst?: string,
    ktr?: string,
    midas?: string,
    jobNumber?: string,
    status?: string,
    startDate: Dayjs | null,
    endDate: Dayjs | null,
    constructionBegin: Dayjs | null,
    deconstructionBegin: Dayjs | null,
    deliveryDate: Dayjs | null,
    pickupDate: Dayjs | null,
    deliveryInstructions?: string,
    internNotes?: string,
    updatedAt?: Dayjs,
    createdAt?: Dayjs,
    requestedAt?: Dayjs,

    user?: IUser,
    address?: IAddress,
    contactPerson?: IContactPerson,
    contactPersonExternal?: IContactPerson,

    userId?: string,
    addressId?: string,
    contactId?: string,
    contactIdExternal?: string,
}

export const ProjectStatus: Record<string, string> = {
    Offen: 'Offen',
    Angefragt: 'Angefragt',
    Beantwortet: 'Beantwortet / KVA erstellt',
    Bestellt: 'Bestellt / Bestätigt',
    Kommissionierung: 'Kommissionierung',
    Abgeholt: 'Abgeholt / Unterwegs',
    Zurueckgeliefert: 'Zurueckgeliefert',
    Geprueft: 'Geprüft / Zurückgeführt',
    Abgeschlossen: 'Abgeschlossen',
    Storniert: 'Storniert',
};
