import React, {useContext} from 'react';
import {
    Alert,
    Breadcrumbs,
    Button,
    Container,
    Grid,
    IconButton,
    LinearProgress,
    Link,
    Paper,
    TextField,
    Typography,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import {useHistory, useLocation} from 'react-router-dom';
import Article from '../components/Article';
import {IArticleFetched} from '../models/article';
import ErrorHandler from '../components/ErrorHandler';
import {getArticles} from '../service/article.service';
import SelectTenant from '../components/data/SelectTenant';
import {IProgram} from '../models/program';
import {ArrowBack, Download} from '@mui/icons-material';
import {RestClientContext} from "../context";
import { ITenantFetched } from '../models/tenant';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Articles() {
    const history = useHistory();
    const queryParams = useQuery();
    const restClient = useContext(RestClientContext)!;
    const [articles, setArticles] = React.useState<IArticleFetched[]>();

    const [filteredArticles, setFilteredArticles] = React.useState<IArticleFetched[]>([]);
    const [query, setQuery] = React.useState<string>('');
    const [error, setError] = React.useState<string>();
    const [tenant, setTenant] = React.useState<ITenantFetched>();

    const programId = queryParams.get('program');
    const categoryId = queryParams.get('category');
    const programOrCategoryName = queryParams.get('programName') ?? queryParams.get('categoryName');

    const handleDownloadStatisticsExport = async () => {
        try {
            const response = await restClient.get('article/articles/export-statistics', {
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'article-statistics-export.xlsx');
            document.body.appendChild(link);
            link.click();
        } catch (ex) {}
    };

    const handleDownloadExport = async () => {
        try {
            const response = await restClient.get('article/articles/export', {
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'article-export.xlsx');
            document.body.appendChild(link);
            link.click();
        } catch (ex) {}
    };

    const filterArticles = (articles?: IArticleFetched[]) => {
        if (!articles) {
            return [];
        }
        const queryString = query.trim().toLowerCase();
        return articles.filter(a =>
            a.name.toLowerCase().includes(queryString)
            || String(a.id).includes(queryString)
            || a.program?.name?.toLowerCase().includes(queryString)
            || a.category?.name?.toLowerCase().includes(queryString)
            || a.tags.find(t => t.name?.toLowerCase().includes(queryString))
        );
    };

    React.useEffect(() => {
        if (!tenant) {
            return;
        }

        // force loading indicator
        setArticles(undefined);
        setFilteredArticles([]);

        getArticles(restClient, '', tenant.id, programId ?? undefined, categoryId ?? undefined)
            .then(data => {
                setFilteredArticles(filterArticles(data));
                setArticles(data);
            })
            .catch(err => setError(err.response?.data?.message ?? err.message));
    }, [tenant, restClient, programId, categoryId]);

    React.useEffect(() => {
        if (!articles) {
            return;
        }

        const handler = setTimeout(() => {
            setFilteredArticles(filterArticles(articles));
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [query]);

    return (
        <Container maxWidth="lg">
            <Paper style={{ padding: 20 }} variant="elevation" elevation={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link
                                        underline="hover"
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        color="inherit"
                                        href="/"
                                    >
                                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                        Dashboard
                                    </Link>
                                    <Typography color="text.primary">Artikel</Typography>
                                </Breadcrumbs>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            {programOrCategoryName && (
                                <Grid item xs={12}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <IconButton style={{ marginBottom: 7 }} onClick={() => history.goBack()}>
                                                <ArrowBack/>
                                            </IconButton>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" gutterBottom>{programOrCategoryName}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Grid container justifyContent="space-between">
                                    <Grid item>
                                        <SelectTenant
                                            buttonGroup
                                            value={tenant?.id}
                                            onChange={tenant => setTenant(tenant)}
                                            onFirst={tenant => setTenant(tenant)}
                                            allowAll
                                            onAll={() => setTenant(undefined)}
                                        />
                                    </Grid>
                                    <Grid item>
                                        {tenant?.allowedArtikelNutzungsstatistik && (
                                            <Button startIcon={<Download />} onClick={handleDownloadStatisticsExport}>
                                                Nutzungsstatistik exportieren
                                            </Button>
                                        )}
                                        <Button startIcon={<Download />} onClick={handleDownloadExport}>
                                            Artikel exportieren
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Suchen ..."
                                    variant="standard"
                                    onChange={event => setQuery(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ErrorHandler error={error}>
                                    {!articles ? (
                                        <LinearProgress />
                                    ) : (
                                        <Grid container spacing={2} direction="row">
                                            {filteredArticles.length === 0 ? (
                                                <Grid item xs={12}>
                                                    <Alert severity="info">
                                                        Es wurden keine passenden Artikel gefunden.
                                                    </Alert>
                                                </Grid>
                                            ) : filteredArticles.map(article => (
                                                <Grid item xs={12} key={`${article.name}${article.id}`}>
                                                    <Article article={article} />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    )}
                                </ErrorHandler>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default Articles;
