export const promptConstants = {
    SHOW_CONFIRM_PROMPT: 'SHOW_CONFIRM_PROMPT',
    HIDE_CONFIRM_PROMPT: 'HIDE_CONFIRM_PROMPT',
};

const initialState = {
    callback: null,
    title: 'Bestätigen',
    message: 'Möchtest du wirklich diese Aktion ausführen?'
};

export const promptReducer = (state = initialState, { type, callback, title, message }: any) => {
    switch (type) {
        case promptConstants.SHOW_CONFIRM_PROMPT:
            return {
                ...state,
                callback,
                title,
                message,
            };
        case promptConstants.HIDE_CONFIRM_PROMPT:
            return {
                ...state,
                callback: null,
            };
        default:
            return state;
    }
};
