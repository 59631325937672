export const formErrorConstants = {
    SET_ERRORS: 'FORM_ERRORS_SET',
    ADD_ERROR: 'FORM_ERROR_ADD',
    REMOVE_ERROR: 'FORM_ERROR_REMOVE',
    CLEAR: 'FORM_ERROR_CLEAR',
};

const initialState = {
    errors: {},
};

export const formErrorReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case formErrorConstants.SET_ERRORS:
            return {
                ...state,
                errors: action.payload,
            };
        case formErrorConstants.ADD_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    ...action.payload,
                }
            };
        case formErrorConstants.REMOVE_ERROR:
            // TODO
            return state;
        case formErrorConstants.CLEAR:
            return initialState;
        default:
            return state;
    }
};
