import React from 'react';
import {
    Alert,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Grow,
    IconButton,
    TextField,
    Typography
} from '@mui/material';
import {IArticleAmount, IArticleFetched} from '../../models/article';
import CloseIcon from '@mui/icons-material/Close';
import ImageGallery from '../ImageGallery';
import {FileFetched} from '../../models/file';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import {useDispatch} from 'react-redux';
import {addToCart} from '../../actions/cart.actions';

interface ArticleInfoDialogProps {
    children: JSX.Element,
    article: IArticleFetched,
    hideButtons?: boolean,
}

function ArticleInfoDialog({ children, article, hideButtons }: ArticleInfoDialogProps) {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState<boolean>(false);
    const [amount, setAmount] = React.useState<string>('1');

    const addArticle = () => {
        if (Number(amount) <= 0) {
            return;
        }

        const articleWithAmount: IArticleAmount = {
            ...article,
            amount: Number(amount),
        };

        dispatch(addToCart(articleWithAmount));
        handleClose();
        setAmount('1');
    };

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            {article.name}
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={5}>
                            {article.files && (
                                <ImageGallery images={article.files.map(file => (file as FileFetched))} />
                            )}
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Grid container spacing={0.5}>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs>
                                            <Typography variant="body2">Artikelnummer:</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">{article.id}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs>
                                            <Typography variant="body2">Programm:</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">{article.program?.name}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs>
                                            <Typography variant="body2">Kategorie:</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">{article.category?.name}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs>
                                            <Typography variant="body2">Maße:</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">
                                                B: {article.plainWidth}cm, H: {article.plainHeight}cm, T: {article.plainDepth}cm
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs>
                                            <Typography variant="body2">Lagerbestand:</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">{article.amountTotal} Stück</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs>
                                            <Typography variant="body2">Verpackungsart:</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">{article.packagingType?.name}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs>
                                            <Typography variant="body2">Stk. in Verpackung:</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2">{article.amountPerPackage}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: 10 }}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="body2"><strong>Tags</strong></Typography>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1}>
                                                {(!article.tags || article.tags.length === 0) ? (
                                                    <Grid item xs={12}>
                                                        <Typography variant="body2">Keine Tags vorhanden</Typography>
                                                    </Grid>
                                                ) : article.tags.map(tag => (
                                                    <Grid item key={tag.name}>
                                                        <Chip label={tag.name} size="small" />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: 10 }}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="body2"><strong>Beschreibung</strong></Typography>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>{article.description ?? 'Keine Beschreibung vorhanden'}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                {!hideButtons && (
                    <DialogActions>
                        <Grid container justifyContent="end" spacing={2}>
                            <Grow in={Number(amount) > article.amountTotal} unmountOnExit>
                                <Grid item xs={12}>
                                    <Alert severity="warning">
                                        Es sind nicht genügend Artikel im Lagerbestand.
                                    </Alert>
                                </Grid>
                            </Grow>
                            <Grid item>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item>
                                        <IconButton disabled={Number(amount) <= 1} onClick={() => setAmount(String(Number(amount) - 1))}>
                                            <RemoveIcon fontSize="small" />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            style={{ width: 60, marginBottom: 10 }}
                                            label="Anzahl"
                                            variant="standard"
                                            size="small"
                                            value={amount}
                                            onChange={event => setAmount(event.target.value)}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <IconButton disabled={Number(amount) > 9999} onClick={() => setAmount(String(Number(amount) + 1))}>
                                            <AddIcon fontSize="small" />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" onClick={addArticle} disabled={Number(amount) > article.amountTotal}>
                                            Zum Warenkorb hinzufügen
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogActions>
                )}
            </Dialog>

            <span onClick={handleOpen}>
                {children}
            </span>
        </React.Fragment>
    );
}

export default ArticleInfoDialog;
