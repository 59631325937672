import React, {useContext} from 'react';
import {Breadcrumbs, Container, Grid, Link, Typography} from '@mui/material';
import Paper from '@mui/material/Paper';
import HomeIcon from '@mui/icons-material/Home';
import {IProgram} from '../models/program';
import {RestClientContext} from "../context";
import {useHistory} from "react-router-dom";
import ImagePreview from "../components/ImagePreview";
import noImage from "../images/no_image.png";

function ProgramCard({ program }: { program: IProgram }) {
    const history = useHistory();

    return (
        <Paper
            variant="outlined" sx={{ p: 2 }}
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(`/artikel?program=${program.id}&programName=${program.name}`)}
        >
            <Grid direction="column" container justifyContent="center" alignItems="center" style={{ height: 150 }}>
                <Grid item>
                    {program?.imageUrl ? (
                        <ImagePreview url={program.imageUrl}/>
                    ) : (
                        <img
                            src={noImage}
                            width="100%"
                            height="auto"
                            alt="Kein Bild"
                        />
                    )}
                </Grid>
            </Grid>
            <Typography variant="body1">
                {program.name}
            </Typography>
        </Paper>
    );
}

function ProgramOverview() {
    const restClient = useContext(RestClientContext)!;
    const [programs, setPrograms] = React.useState<IProgram[]>([]);

    React.useEffect(() => {
        restClient.get('article/brandingPrograms')
            .then(res => {
                setPrograms(res.data);
            })
            .catch(console.error);
    }, []);

    return (
        <Container maxWidth="xl">
            <Paper style={{ padding: 20 }} variant="elevation" elevation={3}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Breadcrumbs aria-label="breadcrumb">
                                            <Link
                                                underline="hover"
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                                color="inherit"
                                                href="/"
                                            >
                                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                                Dashboard
                                            </Link>
                                            <Typography color="text.primary">Markenübersicht</Typography>
                                        </Breadcrumbs>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5">Markenübersicht</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            {programs.map(program => (
                                <Grid item key={`program${program.id}`} xs={6} md={4} lg={2}>
                                    <ProgramCard program={program} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default ProgramOverview;
