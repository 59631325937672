import React, {useContext} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {clearFormErrors} from '../../actions/formError.actions';
import {IContactPerson} from '../../models/contact';
import {createContactPerson} from '../../service/contact.service';
import {RestClientContext} from "../../context";

interface AddContactDialogProps {
    onFinished: (contact: IContactPerson) => void,
    children: JSX.Element,
}

const initialData: IContactPerson = {
    name: '',
    phoneNumber: '',
    mobileNumber: '',
    email: '',
};

function AddContactDialog({ onFinished, children }: AddContactDialogProps) {
    const restClient = useContext(RestClientContext)!;
    const dispatch = useDispatch();
    const formErrors = useSelector((store: any) => store.formErrorReducer.errors);
    const [open, setOpen] = React.useState<boolean>(false);
    const [processing, setProcessing] = React.useState<boolean>(false);
    const [contact, setContact] = React.useState<IContactPerson>(initialData);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const handleSubmit = async () => {
        dispatch(clearFormErrors());
        setProcessing(true);

        try {
            const updatedContact = await createContactPerson(restClient, contact);

            setOpen(false);
            onFinished(updatedContact);
            setContact(initialData);
        } catch (ex) {}

        setProcessing(false);
    };

    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
                <DialogTitle>Ansprechpartner hinzufügen</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} style={{ marginTop: 5 }}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                size="small"
                                label="Name"
                                value={contact.name}
                                onChange={event => setContact({ ...contact, name: event.target.value })}
                                error={Boolean(formErrors.name)}
                                helperText={formErrors.name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                size="small"
                                label="Telefon"
                                value={contact.phoneNumber}
                                onChange={event => setContact({ ...contact, phoneNumber: event.target.value })}
                                error={Boolean(formErrors.phoneNumber)}
                                helperText={formErrors.phoneNumber}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                size="small"
                                label="Email"
                                value={contact.email}
                                onChange={event => setContact({ ...contact, email: event.target.value })}
                                error={Boolean(formErrors.email)}
                                helperText={formErrors.email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                size="small"
                                label="Handy"
                                value={contact.mobileNumber}
                                onChange={event => setContact({ ...contact, mobileNumber: event.target.value })}
                                error={Boolean(formErrors.mobileNumber)}
                                helperText={formErrors.mobileNumber}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Abbrechen</Button>
                    <Button disabled={processing} variant="contained" onClick={handleSubmit}>Speichern</Button>
                </DialogActions>
            </Dialog>

            <span onClick={handleOpen}>
                {children}
            </span>
        </React.Fragment>
    );
}

export default AddContactDialog;
