import { IContactPerson } from '../models/contact';
import { AxiosInstance } from "axios";

export const getContactPersons = async (rest: AxiosInstance): Promise<IContactPerson[]> => {
    const { data } = await rest.get('contacts');

    return data;
};

export const createContactPerson = async (rest: AxiosInstance, contact: IContactPerson): Promise<IContactPerson> => {
    const { data } = await rest.post(`contacts`, contact);

    return data;
};
