import React, {useContext} from 'react';
import {
    Breadcrumbs,
    Button,
    Chip,
    Container,
    Grid,
    IconButton,
    LinearProgress,
    Link,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ProjectDialog from '../../components/dialogs/ProjectDialog';
import Paper from '@mui/material/Paper';
import {IProject} from '../../models/project';
import DeleteButton from '../../components/DeleteButton';
import DataTable from '../../components/DataTable';
import {duplicateProject, getProjects} from '../../service/project.service';
import {IUser} from '../../models/user';
import AddIcon from '@mui/icons-material/Add';
import {CopyAll, Edit} from '@mui/icons-material';
import ProjectCartCount from '../../components/ProjectCartCount';
import {GridColDef} from '@mui/x-data-grid';
import {RestClientContext} from "../../context";
import toast, { Toaster } from 'react-hot-toast';

interface ProjectsProps {
    user: IUser,
    name?: string,
}

function Projects({ name, user }: ProjectsProps) {
    const restClient = useContext(RestClientContext)!;
    const [projects, setProjects] = React.useState<IProject[]>();
    const [selected, setSelected] = React.useState<IProject>();
    const [query, setQuery] = React.useState<string>('');

    React.useEffect(() => fetchData(), [query]);

    const fetchData = () => {
        getProjects(restClient, query)
            .then(setProjects)
            .catch(console.error);
    };

    const handleDuplicateProject = async (id: string) => {
        const response = await duplicateProject(restClient, id);
        if (response?.status === 200)
        {
            notify(response.data.name);
        }
        fetchData();
    };
    const notify = (name: string) => toast(`Projekt wurde als "${name}" dupliziert. Bitte überprüfen Sie ggf. die Artikelverfügbarkeiten im Warenkorb des Projekts.`, { icon: 'ℹ️' });

    const columns: GridColDef<IProject>[] = [
        {
            field: 'projectId',
            headerName: 'ID',
            flex: 0.1,
        },
        {
            field: 'name',
            headerName: 'VA Name',
            flex: 0.4,
        },
        {
            field: 'startDate',
            headerName: 'Von',
            flex: 0.1,
            renderCell: ({ row }) => row.startDate?.format('DD.MM.YYYY'),
        },
        {
            field: 'endDate',
            headerName: 'Bis',
            flex: 0.1,
            renderCell: ({ row }) => row.endDate?.format('DD.MM.YYYY'),
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.2,
            renderCell: ({ row }) => <Chip label={row.status} />,
        },
        {
            field: '_id',
            headerName: 'Aktionen',
            flex: 0.15,
            align: 'right',
            headerAlign: 'right',
            renderCell: ({ row }) => {
                return (
                    <div>
                        <Tooltip title="Projekt duplizieren">
                            <IconButton
                                disabled={row.status != 'Abgeschlossen'}
                                onClick={() => handleDuplicateProject(row.id!)}
                            >
                                <CopyAll />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Warenkorb ansehen">
                            <ProjectCartCount projectId={row.id!} />
                        </Tooltip>
                        <ProjectDialog onFinish={fetchData} initialData={row}>
                            <IconButton>
                                <Edit />
                            </IconButton>
                        </ProjectDialog>
                        <DeleteButton
                            confirm
                            resource={`projects/${row.id}`}
                            onDelete={fetchData}
                        />
                    </div>
                );
            },
        },
    ];

    return (
        <Container maxWidth="xl">
            <Paper style={{ padding: 20 }} variant="elevation" elevation={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link
                                        underline="hover"
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        color="inherit"
                                        href="/"
                                    >
                                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                        Dashboard
                                    </Link>
                                    <Typography color="text.primary">Projekte</Typography>
                                </Breadcrumbs>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <ProjectDialog onFinish={fetchData} initialUser={user} userName={name}>
                            <Button startIcon={<AddIcon />} variant="contained">Neues Projekt erstellen</Button>
                        </ProjectDialog>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Suchen ..."
                            variant="standard"
                            value={query}
                            onChange={event => setQuery(event.target.value)}
                        />
                    </Grid>
                    {selected && (
                        <ProjectDialog
                            onFinish={fetchData}
                            initialOpen={true}
                            initialData={selected}
                            onClose={() => setSelected(undefined)}
                        />
                    )}
                    <Grid item xs={12}>
                        {!projects ? (
                            <LinearProgress />
                        ) : (
                            <DataTable
                                onCellClick={(params) => {
                                    if (params.field === '_id') {
                                        return;
                                    }

                                    setSelected(params.row);
                                }}
                                rows={projects}
                                columns={columns}
                            />
                        )}
                    </Grid>
                </Grid>
            </Paper>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
        </Container>
    );
}

export default Projects;
