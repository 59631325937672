import React, {useContext} from 'react';
import {Breadcrumbs, Container, Grid, Link, Typography} from '@mui/material';
import Paper from '@mui/material/Paper';
import HomeIcon from '@mui/icons-material/Home';
import {RestClientContext} from "../context";
import {IArticleCategory} from "../models/article";
import {useHistory} from "react-router-dom";

function CategoryCard({ category }: { category: IArticleCategory }) {
    const history = useHistory();

    return (
        <Paper
            variant="outlined" sx={{ p: 2 }}
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(`/artikel?category=${category.id}&categoryName=${category.name}`)}
        >
            <Typography variant="body1">
                {category.name}
            </Typography>
        </Paper>
    );
}

function CategoryOverview() {
    const restClient = useContext(RestClientContext)!;
    const [categories, setCategories] = React.useState<IArticleCategory[]>([]);

    React.useEffect(() => {
        restClient.get('article/categories')
            .then(res => {
                setCategories(res.data);
            })
            .catch(console.error);
    }, []);

    return (
        <Container maxWidth="xl">
            <Paper style={{ padding: 20 }} variant="elevation" elevation={3}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Breadcrumbs aria-label="breadcrumb">
                                            <Link
                                                underline="hover"
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                                color="inherit"
                                                href="/"
                                            >
                                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                                Dashboard
                                            </Link>
                                            <Typography color="text.primary">Kategorien</Typography>
                                        </Breadcrumbs>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5">Kategorien</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            {categories.map(category => (
                                <Grid item key={category.name} xs={6} md={4} lg={2}>
                                    <CategoryCard category={category} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default CategoryOverview;
