import { ITenant, ITenantFetched } from '../models/tenant';
import { AxiosInstance } from "axios";

export const getTenants = async (rest: AxiosInstance): Promise<ITenantFetched[]> => {
    const { data } = await rest.get('tenants');

    return data;
};

export const addTenant = (rest: AxiosInstance, tenant: ITenant): Promise<void> => {
    return rest.post('tenants', tenant);
};

export const updateTenant = (rest: AxiosInstance, tenant: ITenantFetched): Promise<void> => {
    return rest.patch(`tenants/${tenant.id}`, tenant);
};

export const deleteTenant = (rest: AxiosInstance, tenant: ITenantFetched) => {
    return rest.delete(`tenants/${tenant.id}`);
};