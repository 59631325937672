import React, {useContext} from 'react';
import {
    Alert,
    Avatar,
    Badge,
    BadgeProps,
    Button,
    Divider,
    Grid,
    Grow,
    IconButton,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    Tooltip,
    Typography
} from '@mui/material';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import DeleteIcon from '@mui/icons-material/Delete';
import {useDispatch, useSelector} from 'react-redux';
import {getDimensions, IArticleAmount} from '../models/article';
import {styled} from '@mui/material/styles';
import {clearCart, removeFromCart} from '../actions/cart.actions';
import SelectProject from './data/SelectProject';
import {IProject} from '../models/project';
import {IUser} from '../models/user';
import noImage from '../images/no_image.png';
import {FileFetched} from '../models/file';
import {addProjectCartItem} from '../service/cart.service';
import {useHistory} from 'react-router-dom';
import Keycloak from 'keycloak-js';
import {RestClientContext} from "../context";

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

function ShoppingCart({ user, keyCloak }: { user: IUser, keyCloak: Keycloak.KeycloakInstance }) {
    const restClient = useContext(RestClientContext)!;
    const dispatch = useDispatch();
    const history = useHistory();
    const shoppingCart = useSelector((store: any) => store.cartReducer);
    const [project, setProject] = React.useState<IProject>();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [error, setError] = React.useState<string>();
    const [processing, setProcessing] = React.useState<boolean>(false);
    const open = Boolean(anchorEl);
    const cartSize = shoppingCart.articles.length;

    const handleSubmit = async () => {
        if (!project) {
            return;
        }

        setProcessing(true);
        setError(undefined);

        try {
            await Promise.all(
                shoppingCart.articles.map(async (article: IArticleAmount) => {
                    await addProjectCartItem(restClient, project.id!, article.id, article.amount);
                })
            );

            history.push(`/projekte/${project.id}/warenkorb`);

            dispatch(clearCart());
            setAnchorEl(null);
        } catch (err: any) {
            if (err.response?.status === 403) {
                setError('Dieses Projekt darf nicht mehr geändert werden.');
            }
        }

        setProcessing(false);
    };

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <Grid container style={{ width: 400, padding: 15, maxHeight: 700 }} justifyContent="end">
                    {shoppingCart.articles.length === 0 ? (
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                Der Warenkorb ist leer.
                            </Typography>
                        </Grid>
                    ) : (
                      <React.Fragment>
                          <Grid item xs={12}>
                              <Typography variant="h6">
                                  Warenkorb
                              </Typography>
                              <Divider />
                          </Grid>
                          <Grid item xs={12}>
                              <List dense>
                                  {shoppingCart.articles
                                      .map((article: IArticleAmount) => (
                                          <ListItemButton key={article.id} style={{ paddingLeft: 5, paddingRight: 0 }}>
                                              <ListItemAvatar>
                                                  <Avatar
                                                      alt="Article Image"
                                                      src={article.files.length === 0 ? noImage : (article.files[0] as FileFetched).url}
                                                  />
                                              </ListItemAvatar>
                                              <ListItemText
                                                  primary={
                                                      <Typography variant="body2">
                                                          {article.amount}x {article.name}
                                                      </Typography>
                                                  }
                                                  secondary={`${getDimensions(article)}ID: ${article.zdfId}`}
                                              />
                                              <ListItemIcon>
                                                  <Tooltip title="Artikel entfernen">
                                                      <IconButton onClick={() => dispatch(removeFromCart(article))}>
                                                          <DeleteIcon fontSize="small" />
                                                      </IconButton>
                                                  </Tooltip>
                                              </ListItemIcon>
                                          </ListItemButton>
                                      ))
                                  }
                              </List>
                          </Grid>
                          <Grow in={Boolean(error)} unmountOnExit>
                              <Grid item xs={12}>
                                  <Alert severity="warning">
                                      {error}
                                  </Alert>
                              </Grid>
                          </Grow>
                          <Grid item xs={12} style={{ marginTop: 40 }}>
                              <SelectProject
                                  onChange={setProject}
                                  user={user}
                                  value={project?.id}
                                  showAll={keyCloak.hasRealmRole('verwaltung_projekte')}
                              />
                          </Grid>
                          <Grid item xs={12} style={{ marginTop: 15 }}>
                              <Button
                                  fullWidth
                                  size="small"
                                  variant="contained"
                                  disabled={!project || processing}
                                  onClick={handleSubmit}
                              >
                                  Zu Projekt hinzufügen
                              </Button>
                          </Grid>
                      </React.Fragment>
                    )}
                </Grid>
            </Menu>

            <Tooltip title="Warenkorb">
                <IconButton style={{ color: 'white' }} onClick={handleClick}>
                    {cartSize > 0 ? (
                        <StyledBadge color="primary" badgeContent={cartSize}>
                            <ShoppingBasketIcon />
                        </StyledBadge>
                    ) : (
                        <ShoppingBasketIcon />
                    )}
                </IconButton>
            </Tooltip>
        </React.Fragment>
    );
}

export default ShoppingCart;
