import React, {useContext} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Grow,
    TextField,
    Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {DesktopDateTimePicker} from '@mui/x-date-pickers';
import {IProject, ProjectStatus} from '../../models/project';
import {cancelProject, createProject, updateProject} from '../../service/project.service';
import {IUser} from '../../models/user';
import SelectAddress from '../data/SelectAddress';
import {useDispatch, useSelector} from 'react-redux';
import {clearFormErrors} from '../../actions/formError.actions';
import {DoDisturb} from '@mui/icons-material';
import {setPrompt} from '../../actions/prompt.actions';
import SelectContact from '../data/SelectContact';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import {useHistory} from 'react-router-dom';
import {RestClientContext} from "../../context";

interface AddProjectDialogProps {
    onFinish: () => void,
    onClose?: () => void,
    initialUser?: IUser,
    initialData?: IProject,
    intern?: boolean,
    children?: any,
    userName?: string,
    initialOpen?: boolean,
    readOnly?: boolean,
}

const defaultData: IProject = {
    name: '',
    status: 'Offen',
    startDate: null,
    endDate: null,
    constructionBegin: null,
    deconstructionBegin: null,
    deliveryDate: null,
    pickupDate: null,
    deliveryInstructions: '',
};

function ProjectDialog({ onFinish, onClose, initialUser, initialData, children, userName, initialOpen }: AddProjectDialogProps) {
    const restClient = useContext(RestClientContext)!;
    const dispatch = useDispatch();
    const history = useHistory();
    const formErrors = useSelector((store: any) => store.formErrorReducer.errors);
    const [open, setOpen] = React.useState<boolean>(initialOpen ?? false);
    const [project, setProject] = React.useState<IProject>(initialData ?? defaultData);
    const [processing, setProcessing] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>();

    React.useEffect(() => {
        if (!open || !initialData?.user) {
            return;
        }
    }, [open]);

    const user: IUser = initialUser ?? initialData!.user!;

    const isCanceled = (): boolean => project.status === ProjectStatus.Storniert;

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setOpen(false);
        if (onClose) {
            onClose();
        }
    };

    const handleSubmit = async (): Promise<void> => {
        dispatch(clearFormErrors());

        const dateFormat: string = 'YYYY-MM-DDTHH:mm:00';

        const updated: any = {
            ...project,
            kst: (project.kst && project.kst.length > 0) ? Number(project.kst) : undefined,
            ktr: (project.ktr && project.ktr.length > 0) ? Number(project.ktr) : undefined,
            midas: (project.midas && project.midas.length > 0) ? Number(project.midas) : undefined,
            startDate: project.startDate?.format(dateFormat),
            endDate: project.endDate?.format(dateFormat),
            constructionBegin: project.constructionBegin ? project.constructionBegin.format(dateFormat) : null,
            deconstructionBegin: project.deconstructionBegin ? project.deconstructionBegin.format(dateFormat) : null,
            deliveryDate: project.deliveryDate ? project.deliveryDate.format(dateFormat) : null,
            pickupDate: project.pickupDate ? project.pickupDate.format(dateFormat) : null,
            addressId: project.addressId ?? null,
            contactId: project.contactId ?? null,
            contactIdExternal: project.contactIdExternal ?? null,
        };

        setProcessing(true);
        try {
            if (!initialData) {
                await createProject(restClient, updated);
            } else {
                await updateProject(restClient, updated);
            }
        } catch (ex: any) {
            setProcessing(false);

            if (ex.response?.status === 403) {
                setError('Das Projekt kann nicht geändert werden, weil es bereits angefragt ist.');
            }

            return;
        }

        setProcessing(false);
        handleClose();
        onFinish();
    };

    const handleCancel = async (confirm: boolean): Promise<void> => {
        if (!confirm) {
            dispatch(setPrompt('Wirklich stornieren?', '', () => handleCancel(true)));
            return
        }

        setProcessing(true);

        await cancelProject(restClient, project.id!);

        setProcessing(false);
        handleClose();
        onFinish();
    }

    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            {initialData ? 'Projekt bearbeiten' : 'Neues Projekt erstellen'}
                        </Grid>
                        {initialData && (
                            <Grid item>
                                <Button onClick={() => history.push(`/projekte/${project.id}/warenkorb`)} variant="outlined" startIcon={<ShoppingBasketIcon />}>
                                    Warenkorb
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Grid container spacing={2} style={{ marginTop: 5 }}>
                                <Grow in={Boolean(error)} unmountOnExit>
                                    <Grid item xs={12} style={{ marginBottom: 10 }}>
                                        <Alert severity="warning">
                                            {error}
                                        </Alert>
                                    </Grid>
                                </Grow>
                                {isCanceled() && (
                                    <Grid item xs={12} mb={2}>
                                        <Alert severity="warning">
                                            Dieses Projekt ist storniert und kann nicht mehr geändert werden.
                                        </Alert>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Projektname"
                                        size="small"
                                        value={project.name}
                                        onChange={event => setProject({ ...project, name: event.target.value })}
                                        error={Boolean(formErrors.name)}
                                        helperText={formErrors.name}
                                        disabled={isCanceled()}
                                    />
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    <TextField
                                        fullWidth
                                        label="PB / PO-Nummer"
                                        size="small"
                                        value={project.pb}
                                        onChange={event => setProject({ ...project, pb: event.target.value })}
                                        error={Boolean(formErrors.pb)}
                                        helperText={formErrors.pb ?? 'Projektbestandteil, Freitext'}
                                        disabled={isCanceled()}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        label="KST"
                                        size="small"
                                        value={project.kst}
                                        onChange={event => setProject({ ...project, kst: event.target.value })}
                                        error={Boolean(formErrors.kst)}
                                        helperText={formErrors.kst}
                                        disabled={isCanceled()}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        label="KTR"
                                        size="small"
                                        value={project.ktr}
                                        onChange={event => setProject({ ...project, ktr: event.target.value })}
                                        error={Boolean(formErrors.ktr)}
                                        helperText={formErrors.ktr}
                                        disabled={isCanceled()}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        label="MIDaS"
                                        size="small"
                                        value={project.midas}
                                        onChange={event => setProject({ ...project, midas: event.target.value })}
                                        error={Boolean(formErrors.midas)}
                                        helperText={formErrors.midas}
                                        disabled={isCanceled()}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <DesktopDateTimePicker
                                        label="VA-Startdatum"
                                        inputFormat="DD.MM.YYYY HH:mm"
                                        ampm={false}
                                        value={project.startDate}
                                        onChange={(value: any) => setProject({ ...project, startDate: value })}
                                        renderInput={(params: any) => (
                                            <TextField
                                                required
                                                fullWidth
                                                size="small"
                                                error={Boolean(formErrors.startDate)}
                                                helperText={formErrors.startDate}
                                                {...params}
                                            />
                                        )}
                                        maxDate={project.endDate}
                                        disabled={isCanceled()}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DesktopDateTimePicker
                                        label="VA-Enddatum"
                                        inputFormat="DD.MM.YYYY HH:mm"
                                        ampm={false}
                                        value={project.endDate}
                                        onChange={(value: any) => setProject({ ...project, endDate: value })}
                                        renderInput={(params: any) => (
                                            <TextField
                                                required
                                                fullWidth
                                                size="small"
                                                error={Boolean(formErrors.endDate)}
                                                helperText={formErrors.endDate}
                                                {...params}
                                            />
                                        )}
                                        minDate={project.startDate}
                                        disabled={isCanceled()}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DesktopDateTimePicker
                                        label="Aufbau Beginn"
                                        inputFormat="DD.MM.YYYY HH:mm"
                                        ampm={false}
                                        value={project.constructionBegin}
                                        onChange={(value: any) => setProject({ ...project, constructionBegin: value })}
                                        renderInput={(params: any) => (
                                            <TextField
                                                fullWidth
                                                size="small"
                                                error={Boolean(formErrors.constructionBegin)}
                                                helperText={formErrors.constructionBegin}
                                                {...params}
                                            />
                                        )}
                                        disabled={isCanceled()}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DesktopDateTimePicker
                                        label="Abbau Beginn"
                                        inputFormat="DD.MM.YYYY HH:mm"
                                        ampm={false}
                                        value={project.deconstructionBegin}
                                        onChange={(value: any) => setProject({ ...project, deconstructionBegin: value })}
                                        renderInput={(params: any) => (
                                            <TextField
                                                fullWidth
                                                size="small"
                                                error={Boolean(formErrors.deconstructionBegin)}
                                                helperText={formErrors.deconstructionBegin}
                                                {...params}
                                            />
                                        )}
                                        disabled={isCanceled()}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DesktopDateTimePicker
                                        label="Auslieferung über u!s"
                                        inputFormat="DD.MM.YYYY HH:mm"
                                        ampm={false}
                                        value={project.deliveryDate}
                                        onChange={(value: any) => setProject({ ...project, deliveryDate: value })}
                                        renderInput={(params: any) => (
                                            <TextField
                                                fullWidth
                                                size="small"
                                                error={Boolean(formErrors.deliveryDate)}
                                                helperText={formErrors.deliveryDate}
                                                {...params}
                                            />
                                        )}
                                        maxDate={project.pickupDate}
                                        disabled={Boolean(project.pickupDate) || isCanceled()}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DesktopDateTimePicker
                                        label="Selbstabholung über Kunde"
                                        inputFormat="DD.MM.YYYY HH:mm"
                                        ampm={false}
                                        value={project.pickupDate}
                                        onChange={(value: any) => setProject({ ...project, pickupDate: value })}
                                        renderInput={(params: any) => (
                                            <TextField
                                                fullWidth
                                                size="small"
                                                error={Boolean(formErrors.pickupDate)}
                                                helperText={formErrors.pickupDate}
                                                {...params}
                                            />
                                        )}
                                        disabled={Boolean(project.deliveryDate) || isCanceled()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={3}
                                        maxRows={4}
                                        label="Lieferanweisung / Kommentar"
                                        value={project.deliveryInstructions}
                                        onChange={event => setProject({ ...project, deliveryInstructions: event.target.value })}
                                        disabled={isCanceled()}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant="h6">Besteller</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {project.address?.id ? (
                                        <TextField
                                            required
                                            fullWidth
                                            size="small"
                                            value={`${userName ?? user.name} (${project.address?.addressStreet}, ${project.address?.addressZip} ${project.address?.addressLocation})`}
                                            disabled
                                        />
                                    ) : (
                                        <TextField
                                            required
                                            fullWidth
                                            size="small"
                                            value={`${userName ?? user.name} (${user.addressStreet} ${user.addressHouseNumber}, ${user.addressZip} ${user.addressLocation})`}
                                            disabled
                                        />
                                    )}
                                </Grid>

                                <Grid item xs={12} />

                                <Grid item xs={12}>
                                    <Accordion variant="outlined" defaultExpanded>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Location & Ansprechpartner</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <SelectAddress
                                                        onChange={id => setProject({ ...project, addressId: id })}
                                                        value={project.addressId}
                                                        readOnly={isCanceled()}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <SelectContact
                                                        name="Ansprechpartner (intern)"
                                                        onChange={id => setProject({ ...project, contactId: id })}
                                                        value={project.contactId}
                                                        readOnly={isCanceled()}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <SelectContact
                                                        name="Ansprechpartner (extern)"
                                                        onChange={id => setProject({ ...project, contactIdExternal: id })}
                                                        value={project.contactIdExternal}
                                                        readOnly={isCanceled()}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="space-between">
                        <Grid item style={{ marginLeft: 10 }}>
                            {initialData && !isCanceled() && (
                                <Button
                                    startIcon={<DoDisturb />}
                                    disabled={processing}
                                    onClick={() => handleCancel(false)}
                                >
                                    Stornieren
                                </Button>
                            )}
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <Button onClick={handleClose}>Abbrechen</Button>
                                </Grid>
                                {!isCanceled() && (
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            onClick={handleSubmit}
                                            disabled={processing}
                                        >
                                            Speichern
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>

            {children && (
                <span onClick={handleOpen}>
                    {children}
                </span>
            )}
        </React.Fragment>
    )
}

export default ProjectDialog;
