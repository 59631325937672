import { IArticleAmount, StoredArticle } from '../models/article';

export const cartConstants = {
    ADD_TO_CART: 'ADD_TO_CART',
    ADD_TO_CART_PLAIN: 'ADD_TO_CART_PLAIN',
    REMOVE_FROM_CART: 'REMOVE_FROM_CART',
    CLEAR_CART: 'CLEAR_CART',
};

const persistCart = (articles: IArticleAmount[]) => {
    const mappedArticles: StoredArticle[] = articles.map(article => ({
        id: article.id,
        amount: article.amount,
    }));

    localStorage.setItem('cart', JSON.stringify(mappedArticles));
};

const initialState: { articles: IArticleAmount[] } = {
    articles: [],
};

export const cartReducer = (state = initialState, { type, payload }: { type: string, payload: IArticleAmount }) => {
    switch (type) {
        case cartConstants.ADD_TO_CART:

            // check if article is already in cart
            const foundArticle = state.articles.find(article => article.id === payload.id);

            if (foundArticle) {
                const mergedArticles = {
                    ...state,
                    articles: [
                        ...state.articles.filter(article => article.id !== foundArticle.id),
                        {
                            ...foundArticle,
                            amount: foundArticle.amount + payload.amount,
                        },
                    ],
                };

                persistCart(mergedArticles.articles);

                return mergedArticles;
            }

            const updatedAdd = {
                ...state,
                articles: [...state.articles, payload],
            };

            persistCart(updatedAdd.articles);

            return updatedAdd;
        case cartConstants.ADD_TO_CART_PLAIN:
            return {
                ...state,
                articles: [...state.articles, payload],
            };
        case cartConstants.REMOVE_FROM_CART:
            const updatedRemove = {
                ...state,
                articles: state.articles.filter(article => article !== payload),
            };

            persistCart(updatedRemove.articles);

            return updatedRemove;
        case cartConstants.CLEAR_CART:
            persistCart([]);

            return {
                ...state,
                articles: [],
            };
        default:
            return state;
    }
};
