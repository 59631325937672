import { formErrorConstants } from '../reducers/formError.reducer';

export const setFormErrors = (errors: any) => ({
    type: formErrorConstants.SET_ERRORS,
    payload: errors,
});

export const addFormError = (formError: any) => ({
    type: formErrorConstants.ADD_ERROR,
    payload: formError,
});

export const removeFormError = (formError: any) => ({
    type: formErrorConstants.REMOVE_ERROR,
    payload: formError,
});

export const clearFormErrors = () => ({
    type: formErrorConstants.CLEAR,
});
