import { IProgram } from './program';
import { IStorageArea, IStorageAreaFetched } from './storageArea';
import { ITag, ITagFetched } from './tag';
import { IPackagingType } from './packagingType';
import { ITenantFetched } from './tenant';

export interface IArticleAmount extends IArticleFetched {
    amount: number,
}

export interface IArticleProjectItem {
    id: string,
    amount: number | string,
    siteNotes?: string,
    article: IArticleFetched,
    updatedAt: string,
    createdAt: string,
}

export interface StoredArticle {
    id: string,
    amount: number,
}

export interface IArticleFetched extends IArticle {
    id: string,
    category?: IArticleCategory,
    program?: IProgram,
    storageAreas: IStorageAreaFetched[],
    packagingType: IPackagingType
    tags: ITagFetched[],
}

export interface IArticle {
    id?: string,
    zdfId?: string,
    name: string,

    category?: IArticleCategory,
    program?: IProgram,
    tenant?: ITenantFetched,
    storageAreas?: IStorageArea[],
    tags?: ITag[],

    productionPrice?: number,
    sellingPrice?: number,

    plainHeight?: number,
    plainWidth?: number,
    plainDepth?: number,

    packagedHeight?: number,
    packagedWidth?: number,
    packagedDepth?: number,

    packagedWeight?: number,
    plainWeight?: number,

    amountTotal: number,
    amountToday: number,
    amountPerPackage?: number,
    packagingType?: IPackagingType,
    description?: string,

    productionDate?: Date | null,
    clothingSizes?: string,
    seasonal: boolean,
    merch: boolean,
    active: boolean,

    files: File[],
}

export interface IArticleCategory {
    id?: string,
    name?: string,
    description?: string,
}

export const getDimensions = (article: IArticleFetched): string => {
    if (!article.plainHeight || !article.plainWidth || !article.plainDepth) {
        return '';
    }

    return `H: ${article.plainHeight}cm B: ${article.plainWidth}cm T: ${article.plainDepth}cm, `;
};
