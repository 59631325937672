import React from 'react';
import {DataGrid} from '@mui/x-data-grid';
import {GridEventListener} from '@mui/x-data-grid/models/events';

interface DataTableProps {
    columns: any[],
    rows: any[],
    onCellClick?: GridEventListener<'cellClick'>,
}

function DataTable({ columns, rows, onCellClick }: DataTableProps) {
    const [pages, setPages] = React.useState<number>(10);

    return (
        <DataGrid
            rows={rows}
            columns={columns}
            pageSize={pages}
            autoHeight
            rowsPerPageOptions={[ 5, 10, 20, 50 ]}
            disableSelectionOnClick
            onPageSizeChange={pageSize => setPages(pageSize)}
            onCellClick={onCellClick}
        />
    );
}

export default DataTable;
